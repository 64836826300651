footer {
    padding: 48px 0px;
    margin-top: 64px;
    text-align: center;
}
.footer-nav{
    padding: 0!important;
}
.footer-brand {
    margin: 0!important;
}