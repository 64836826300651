.jumbotron {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../static/images/SolarPanels7.png");
  background-color: white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;  
  height: 600px;
  border: 10px black;
}

.jumbotron .title {
  font-size: 50px;
  color: black;
}

.jumbotron .subtitle {
  font-size: 20px;
  color: white;
}

.introduction {
  display: flex;
  flex-direction: row;
}

.introduction .left {
  align-items: center;
}

.introduction .left .title {
  font-size: 40px;
  color: black;
}

.introduction .left .details {
  font-size: 20px;
  color: black;
}

.introduction .right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.introduction .details {
  font-size: 15px;
  color: black;
}

.introduction .center {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 10px;
}

.introduction .center .title {
  font-size: 40px;
  color: black;
}

.introduction .center .details {
  font-size: 20px;
  color: black;
}

.display-4 {
  font-weight: bold !important;
}

@media (min-width: 768px) {

}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.featurette-image {
  color: grey;
  background-color: gray;
}

.featurette-divider {
  margin: 3rem 0;
  width: 100%;
}

.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}

@media (min-width: 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.a {
  color: black;
  text-decoration: none;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
  text-decoration: none;
}

.homeTitle {
  text-align: left;
  margin-left: -20px;
}

.homeTitle {
  margin-top: 0;
}
