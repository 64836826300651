.item_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.item {
  width: 34rem;
  height: 180px;
  margin-top: 15px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0s;
}

.item:hover {
	transform: translateY(-0.1rem) scale(1.025);
	box-shadow: 0 0.5em 3rem -1rem rgba(255, 0, 0, 0.5);
}
.itemLink {
  color: black;
}
.itemLink:hover {
  text-decoration: none;
}

.cardImg {
  width: 256px;
  height: 150px;
}

.cardText {
  padding: 16px;
  text-align: center;

}
.cardTitle {
  text-align: center;
}

.topCard {
  padding: 16px;
  align-items: center;
}
.topCardGradient {
  color: rgba(245, 245, 245, 0.986);
 background: linear-gradient(67deg, #9a0400, #F71735, #FF9F1C,#256630, #296d7c, #4b1331, #2c286b, #1d5c53, #43134b,#9a0400,#931621);
background: linear-gradient(69deg, #FCB529, #D82A2A);
background-size: 600% 100%;
animation-name: rainbow;
animation-timing-function: linear;
animation-duration: 10s;
animation-delay: 1s;
animation-iteration-count: infinite;
animation-fill-mode: initial;
@keyframes rainbow { 
    0%{background-position:0% 0%}
    50%{background-position:100% 0%}
    100%{background-position:0% 0%}
}
}
