title{
  color: rgb(1, 1, 1);
}


body {
  background-color: #F6F7F9;
  font: 100% sans-serif;
  color: rgb(1, 1, 1);
  line-height: 2.0
}

/*
 * Typography
 */

header{
  text-align: center;
  text-indent: 20px;
}


.h1, .h2, .h3, .h4, .h5, .h6 { font-family: -apple-system, BlinkMacSystemFont; font-weight: 700; }
.h1, .h2 { 
  line-height: 1.1; 
}

.h3, .h4 { 
  line-height: 1.3;
   text-align: center; 
}

h1 { 
  margin-top: 100px;
  font-size: 300%; 
  letter-spacing: -2px; 
  text-align: center;
  text-indent: 20px;
}



.h2 { font-size: 250%; letter-spacing: -2px; }
.h3 { font-size: 200%; }
.h4 { font-size: 180%; }
.h5 { font-size: 130%; }
.h6 { font-size: 100%; }

.projectImage {
  margin: auto;
  width: 100%;
  
}

hr {
  background-color:#555;
}

.projectSum{
 padding: 20px;
 max-width:100%;
 text-align: left;
 display: block;
 margin-left: auto;
 margin-right: auto;
 width: 100%;
 line-height: 2;
 white-space: pre-wrap;

}


.defaultImage {
  max-height: 120px;
  margin: auto;
  
}

